import { USERS_LIST } from "./action";

const init = {
    users: [],
    is_loading: true,
    is_error: false,
    pagination: {
        page: 0,
        page_size: 0,
        total_pages: 0,
        total_documents: 0
    }
};


export const usersReducer = (store = init, { type, payload }) => {

    switch (type) {
        case USERS_LIST: {
            return {
                ...store,
                users: payload.data,
                pagination: {
                    ...payload.pagination,
                    page: payload.pagination.page - 1
                },
            }
        }

        default: {
            return store;
        };
    };
};
