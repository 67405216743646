import { Link, useNavigate } from "react-router-dom"
import styles from "./styles/Navbar.module.css";
import { useDispatch } from "react-redux";
import { authAction } from "../../Redux/Auth/action";
import { Button } from "@mui/material";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleLogout = () => {
    localStorage.removeItem("sov_user");
    dispatch(authAction({
      isAuth: false,
      user: "",
      token: ""
    }))
    navigate("/login", true)
  };

  return (
    <div id={styles.NavbarMainContainer}>
      <Link to="/"><img src="SovrennLogo.svg" alt="logo" id={styles.sovrennLogo} /></Link>

      <div id={styles.NavbarRightDiv}>

        <Button variant="outlined" color="black" onClick={handleLogout}>Logout</Button>
      </div>
    </div>
  )
};

export default Navbar;