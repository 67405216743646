export const USERS_LIST = "USERS_LIST";

const getUsersList = (payload) => {
    return {
        type: USERS_LIST,
        payload
    };
};

export const getUsersListFun = (page, pageSize) => async (dispatch) => {
    try {
        page = page ? page + 1 : 1;
        pageSize = pageSize ? pageSize : 20;

        const res = await fetch(`https://api.sovrenn.com/affiliate/users?page=${page}&page_size=${pageSize}`, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
            }
        });

        const data = await res.json();

        if (data.success) {
            dispatch(getUsersList(data));
        };
    }
    catch (err) {
        console.log(err);
    };
};