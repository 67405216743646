import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box, Pagination, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import UserList from '../Users/UserList';
import { useDispatch, useSelector } from 'react-redux';
import { getMetricsFun } from '../../Redux/Metrics/action';

const Home = () => {
  const dispatch = useDispatch();
  const { totalRevenue, totalSignups, referralCodes } = useSelector((store) => store.metrics)
  const [selectedReferralCode, setSelectedReferralCode] = useState('');

  useEffect(() => {
    dispatch(getMetricsFun());

    return () => {

    };
  }, []);

  const handleReferralCodeChange = (event) => {
    setSelectedReferralCode(event.target.value);
  };

  return (
    <div>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel color='black'>My Referral Codes</InputLabel>
            <Select
              color='black'
              value={selectedReferralCode}
              onChange={handleReferralCodeChange}
              label="My Referral Codes"
            >
              {referralCodes.map((code, index) => (
                <MenuItem key={index} value={code}>
                  {code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Top Tiles for Total Revenue and Total Signups */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={6} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Total Revenue
              </Typography>
              <Typography variant="h6">₹{totalRevenue.toLocaleString('en-IN')}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Total Signups
              </Typography>
              <Typography variant="h6">{totalSignups.toLocaleString('en-IN')}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* User List with Pagination */}
      <UserList />
    </div>
  );
}

export default Home;
