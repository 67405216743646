export const AUTH_ACTION = "AUTH_ACTION";
export const SET_USER = "SET_USER";

export const authAction = (data) => {
    return {
        type: AUTH_ACTION,
        payload: data
    };
};

export const setUser = (payload) => {
    return {
        type: SET_USER,
        payload
    };
};

export const setUserFun = (token) => async (dispatch) => {
    const res = await fetch("https://api.sovrenn.com/user", {
        headers: {
            "Authorization": `${token}`
        }
    });

    const data = await res.json();

    if (!res.ok || !data?.user?.role) {
        localStorage.removeItem("sov_user");
        dispatch(setUser({
            data: null,
            token: null,
            isAuth: false
        }));

        return null;
    };
    
    dispatch(setUser({
        data,
        token: data?.token,
        isAuth: true
    }));
    return null;
};