import { TOTAL_METRICS } from "./action";

const init = {
    totalRevenue: 0,
    totalSignups: 0,
    referralCodes: []
};


export const metricsReducer = (store = init, { type, payload }) => {

    switch (type) {
        case TOTAL_METRICS: {
            return {
                totalRevenue: payload.total_revenue,
                totalSignups: payload.total_signup,
                referralCodes: payload.referral_codes
            }
        }

        default: {
            return store;
        };
    };
};
