import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { Route, Routes } from 'react-router-dom';
import Login from './Components/Login/Login';
import Home from './Components/Home/Home';
import { ProtectedRoute } from './Components/ProtectedRoute';

function App() {
  return (
    <div className="App">
      <Navbar />

      <div className="Routes">
        <Routes>
          <Route path='/login' element={
            <Login />
          } />

          <Route path='/' element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          } />
        </Routes>
      </div>
    </div>
  );
}

export default App;
