import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        black: {
            main: '#000',
            contrastText: '#fff', // White text for contrast
        },
    },
});

export default theme;