export const TOTAL_METRICS = "TOTAL_METRICS";

const getMetrics = (payload) => {
    return {
        type: TOTAL_METRICS,
        payload
    };
};

export const getMetricsFun = ( ) => async (dispatch) => {
    try {
        const res = await fetch(`https://api.sovrenn.com/affiliate/metrics`, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
            }
        });

        const data = await res.json();

        if (data.success) {
            dispatch(getMetrics(data.data));
        };
    }
    catch (err) {
        console.log(err);
    };
};