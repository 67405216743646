import { applyMiddleware, combineReducers, compose, legacy_createStore } from "redux";
import { thunk } from "redux-thunk";
import { authReducer } from "./Auth/reducer";
import { usersReducer } from "./Users/reducer";
import { metricsReducer } from "./Metrics/reducer";

const reducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    metrics: metricsReducer
});

const middleWare = [thunk];

//creating store
export const store = legacy_createStore(reducer, compose(applyMiddleware(...middleWare)));