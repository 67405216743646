import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersListFun } from '../../Redux/Users/action';
import moment from 'moment';

const UserList = () => {
    const dispatch = useDispatch();
    const { users, pagination } = useSelector((store) => store.users)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    useEffect(() => {
        dispatch(getUsersListFun(page, rowsPerPage));
    }, [page, rowsPerPage, dispatch]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table aria-label="user list table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                            }}>Name</TableCell>
                            <TableCell sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                            }}>Status</TableCell>
                            <TableCell sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                            }}>Date Of Signup</TableCell>
                            <TableCell sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                            }}>FA Purchased on</TableCell>
                            <TableCell sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                            }}>Total Spent</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users?.map((user, ind) => (
                            <TableRow key={user.name + ind}>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user?.status}</TableCell>
                                <TableCell>{moment(user?.date_of_signup).format("Do MMM YYYY")}</TableCell>
                                <TableCell>{user?.first_fa_purchase_date ? moment(user?.first_fa_purchase_date).format("Do MMM YYYY") : "NA"}</TableCell>
                                <TableCell>₹{user?.total_spent.toLocaleString('en-IN')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={pagination?.total_documents || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[20, 50, 100]}
            />
        </Paper>
    );
};

export default UserList;