import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authAction } from "../../Redux/Auth/action";
import styles from "./styles/Login.module.css";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState({
        email: "",
        password: ""
    });
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleChange = (ele) => {
        const { value, id } = ele.target;

        setForm({
            ...form,
            [id]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const res = await fetch("https://api.sovrenn.com/login", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(form)
        })
            .then((d) => d.json());


        if (res.success && (res?.user?.role?.includes("admin") || res?.user?.role?.includes("influencer"))) {
            localStorage.setItem("sov_user", res.token);
            dispatch(authAction({
                isAuth: true,
                user: res.user,
                token: res.token
            }))
            navigate(`/`, { replace: true });

            setForm({
                email: "",
                password: ""
            })
            return;
        };

        if (res.success) {
            alert("You do not have permission to access this feature.");
        }
        else {
            alert("Email or password is not correct.");
        };
        
        return;
    }
    return (
        <div id={styles.LoginContainer} >

            <Typography variant="h4" component="h1" sx={{ fontWeight: "bold" }} gutterBottom>
                Login
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    type="email"
                    id="email"
                    value={form.email}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    color="black"
                />
                <TextField
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={form.password}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    color="black"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Button sx={{ marginTop: "20px", fontSize: "larger" }} type="submit" variant="contained" color="black" fullWidth>
                    Login
                </Button>
            </form>

        </div>
    )
};

export default Login;